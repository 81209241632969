export default {
  inputLabel: {
    id: 'email_collector_input_label',
    defaultMessage: 'E-mail',
  },
  toastClose: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  toastSuccessTitle: {
    id: 'email_collector_toast_success_title',
    defaultMessage: 'Perfect!',
  },
  toastSuccessMessage: {
    id: 'email_collector_toast_success_message',
    defaultMessage: 'Thanks for subscribing to our newsletter',
  },
  toastErrorTitle: {
    id: 'email_collector_toast_error_title',
    defaultMessage: 'Oops!',
  },
  toastDefaultErrorMessage: {
    id: 'email_collector_toast_error_message',
    defaultMessage: 'Something went wrong',
  },
  toastInvalidEmailErrorMessage: {
    id: 'email_collector_toast_email_error_message',
    defaultMessage: '{input}: {message}',
  },
  formMandatoryError: {
    id: 'email_collector_mandatory_field_error',
    defaultMessage: 'This field is mandatory',
  },
  checkboxMandatoryError: {
    id: 'email_collector_mandatory_checkbox_error',
    defaultMessage: 'You must accept',
  },
}
