
import { RevButton } from '@ds/components/Button'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevForm, makeValidate, required } from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { RevToast } from '@ds/components/Toast'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import { imageValidator } from '@cms/helpers/image'
import { TOAST_CMS_EMAIL_COLLECTOR } from '@config/constants/toasts'
import { getDefaultLangFromCountry } from '@core/helpers/countries'
import { operationSubscribe } from '@http/endpoints'
import { DEFAULT_ERROR_MESSAGES } from '@http/errorCodes'
import { trackClick } from '@tracking/events'

import ImageRender from '../private/ImageRender'

import translations from './EmailCollector.translations'

const TOAST_VARIANT = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export default {
  components: {
    RevButton,
    RevLink,
    RevCheckbox,
    RevForm,
    ImageRender,
    RevInputText,
    RevToast,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      validator: imageValidator,
      default: () => ({}),
    },
    checkboxLabel: {
      type: String,
      default: '',
    },
    pdf: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      initialValues: {
        email: '',
        lottery: false,
      },
      isLoading: false,
      isToastOpened: false,
      hasCheckboxError: false,
      toast: {
        title: '',
        message: '',
        icon: '',
        variant: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    TOAST_CMS_EMAIL_COLLECTOR: () => TOAST_CMS_EMAIL_COLLECTOR,
    translations: () => translations,

    isButtonDisabled() {
      return this.toast.variant === TOAST_VARIANT.SUCCESS
    },

    hasImage() {
      return !isEmpty(this.image)
    },

    hasCheckbox() {
      return !isEmpty(this.checkboxLabel)
    },

    hasPDF() {
      return !isEmpty(this.pdf)
    },

    toastSuccess() {
      return {
        title: this.$t(translations.toastSuccessTitle),
        message: this.$t(translations.toastSuccessMessage),
        icon: this.$static('/img/toast/rabbit.svg'),
        variant: TOAST_VARIANT.SUCCESS,
      }
    },

    toastError() {
      return {
        title: this.$t(translations.toastErrorTitle),
        message: '',
        icon: this.$static('/img/toast/storm.svg'),
        variant: TOAST_VARIANT.ERROR,
      }
    },
  },
  methods: {
    resetError() {
      this.hasCheckboxError = false
    },

    validate() {
      const mandatoryError = this.$t(this.translations.formMandatoryError)
      const requiredField = required(mandatoryError)

      return makeValidate({
        email: [requiredField],
      })
    },

    sendTrackingData() {
      trackClick({
        ...this.tracking,
        name: this.title,
      })
    },

    async submit(values) {
      if (this.hasCheckbox && !values.lottery) {
        this.hasCheckboxError = true

        return
      }

      this.sendTrackingData()

      try {
        this.isLoading = true

        const { email } = values
        const subscriptionSource =
          this.$route.params.pageName || this.$route.name
        const countryCode = this.country
        const languageCode = getDefaultLangFromCountry(this.country)
        const optin = true

        await this.$store.dispatch('http/request', {
          request: operationSubscribe,
          body: {
            email,
            subscriptionSource,
            optin,
            countryCode,
            languageCode,
          },
        })

        this.toast = this.toastSuccess
      } catch ({ code, payload }) {
        const { email } = get(payload, 'data.fields', {})

        if (code === DEFAULT_ERROR_MESSAGES[422] && !isEmpty(email)) {
          this.toast = {
            ...this.toastError,
            message: this.$t(translations.toastInvalidEmailErrorMessage, {
              input: this.$t(translations.inputLabel),
              message: email[0],
            }),
          }
        } else if (code === DEFAULT_ERROR_MESSAGES[422]) {
          // The user has already subscribed
          this.toast = this.toastSuccess
        } else {
          this.toast = {
            ...this.toastError,
            message: this.$t(translations.toastDefaultErrorMessage),
          }
        }
      } finally {
        this.isLoading = false
        this.isToastOpened = true
      }
    },

    closeToast() {
      this.isToastOpened = false
    },
  },
}
